import React from 'react'
import SbEditable from 'storyblok-react'
import { Field as FormikField } from 'formik'
import { Box, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { emailRegex } from '@helpers/validation'

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    '& input': {
      height: '44px',
      padding: '0px 14px',
      fontSize: '20px',
      boxSizing: 'borderbox',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  textFieldError: {
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
      '&:hover': {
        borderColor: 'red',
      },
      '&:focus': {
        borderColor: 'red',
        '&:hover': {
          borderColor: 'red',
        },
      },
    },
  },
  errorMessage: {
    color: 'red',
  },
}))

const validate = (value, fieldId, isRequired) => {
  let error
  if (!value && isRequired) {
    error = 'Field Required'
  } else if (fieldId === 'Email') {
    if (!emailRegex.test(value)) {
      error = 'Invalid email address'
    }
  }
  return error
}

const CustomText = ({ field, form: { touched, errors }, ...props }) => {
  const classes = useStyles()
  const error = touched[field.name] && errors[field.name] && props.required
  const { defaultValue, fullWidth, margin, name, placeholder, required } = props
  return (
    <>
      <TextField
        key={props.index}
        fullWidth={fullWidth}
        margin={margin || 'normal'}
        defaultValue={defaultValue}
        name={name}
        required={required}
        placeholder={placeholder}
        error={error}
        className={`${classes.textField} ${error && classes.textFieldError}`}
        variant="outlined"
        {...props}
        {...field}
      />
      {error && (
        <Box className={classes.errorMessage}>{errors[field.name]}</Box>
      )}
    </>
  )
}
const MarketoFormText = (props) => {
  const {
    fieldId,
    fieldName,
    required,
    shouldShowPlaceholder = true,
  } = props.blok
  return (
    <SbEditable content={props.blok}>
      <FormikField
        name={fieldId.trim() || 'Text'}
        required={required}
        component={CustomText}
        placeholder={
          shouldShowPlaceholder ? fieldName || 'Please enter a value' : null
        }
        validate={(e) => validate(e, fieldId.trim(), required)}
      />
    </SbEditable>
  )
}
export default MarketoFormText
